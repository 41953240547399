// import * as Sentry from "@sentry/nuxt";
import * as Sentry from "@sentry/browser";
// import { defineNuxtPlugin, useRuntimeConfig } from '#app'

Sentry.init({
  // dsn: import.meta.env.VITE_SENTRY_DSN,
  dsn: "https://022993c84be8a33d10e0b4b807a01ed1@o4505521901142016.ingest.us.sentry.io/4507785256632320",

  // development, testing, staging, or production.
  environment: "production",
  // environment: "development",
  // We recommend adjusting this value in production, or using tracesSampler for finer control
  // tracesSampleRate를 0.1로 설정하면, 10%의 트랜잭션 이벤트만 Sentry로 전송됩니다. 필요에 따라 비율을 조정 가능합니다.
  tracesSampleRate: 0.1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    // Sentry.captureConsoleIntegration(),
    /*Sentry.captureConsoleIntegration({
      // Array of methods that should be captured. Defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ['error']
    }),*/
    // Keep the Replay integration as before
    /*Sentry.replayIntegration({
      // Additional SDK configuration goes in here
      maskAllText: true,
      blockAllMedia: true,
    }),
    // The following is all you need to enable canvas recording with Replay
     Sentry.replayCanvasIntegration()*/
    //Sentry.replayCanvasIntegration({
      // Enabling the following will ensure your canvas elements are not forced into `preserveDrawingBuffer`.
      //enableManualSnapshot: true,
    //}),
  ],
});
/*

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  console.log('sentry init : ', config.SENTRY_DSN, ' / ', config.NODE_ENV)
  Sentry.init({
    dsn: config.SENTRY_DSN as string,

    // development, testing, staging, or production.
    environment: config.NODE_ENV as string,
    // We recommend adjusting this value in production, or using tracesSampler for finer control
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      // Keep the Replay integration as before
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here
        maskAllText: true,
        blockAllMedia: true,
      }),
      // The following is all you need to enable canvas recording with Replay
      Sentry.replayCanvasIntegration()
    ],
  });
})

*/