import { parse, stringify } from 'zipson'

/**
 * system toast : SysNoti, SysNotiMsg
 * 제출 완료 toast : ShowNoti, NotiMsg ==> AlertTopDialogController 로 대체.
 * */
export interface assignmentState {
  uploadVisible: boolean
  previewVisible: boolean
  previewEditable: boolean
  assignIdx: string
  allDone: boolean
  ShowNoti: boolean
  NotiMsg: string
  SysNoti: boolean
  SysNotiMsg: string
  callBack?: () => void
  refreshCallback?: (std_hw_idx: any) => void
  SysNotiRef: HTMLElement | null
  cntResult?: any
  timeOut: number
}

export const useAssignmentsStore = defineStore('window-assignments', {
  state: (): assignmentState => ({
    uploadVisible: false,
    previewVisible: false,
    previewEditable: true,
    assignIdx: '',
    NotiMsg: '',
    ShowNoti: false,
    allDone: false,
    SysNoti: false,
    SysNotiMsg: '',
    SysNotiRef: null,
    cntResult: null,
    timeOut: 1300,
  }),
  actions: {
    init() {
      // this.refreshCallback = () => {}
      this.callBack = () => {}
    },
    setAssginIdx(idx: string) {
      this.assignIdx = idx
    },
    allComplete(std_hw_idx: any) {
      this.allDone = true
      this.refreshCallback?.(std_hw_idx)
    },
    testComplete(subjectNm: string, std_hw_idx: any) {
      this.ShowNoti = true
      this.NotiMsg = subjectNm
      this.refreshCallback?.(std_hw_idx)
    },
    notification(subjectNm: string) {
      this.SysNoti = true
      this.SysNotiMsg = subjectNm
    },
    complete(std_hw_idx: any) {
      this.refreshCallback?.(std_hw_idx)
    },
    assignComplete(subjectNm: string, std_hw_idx: any) {
      this.ShowNoti = true
      this.NotiMsg = subjectNm
      this.refreshCallback?.(std_hw_idx)
    },
    notificationCallback(subjectNm: string, callback: () => void) {
      this.SysNoti = true
      this.SysNotiMsg = subjectNm
      this.callBack = callback
    },
  },
  persist: {
    // CONFIG OPTIONS HERE
    key: 'window-assignments',
    storage: persistedState.sessionStorage,
    serializer: {
      deserialize: parse,
      serialize: stringify,
    },
  },
})
if (import.meta.hot) {
  //HMR
  import.meta.hot.accept(acceptHMRUpdate(useAssignmentsStore, import.meta.hot))
}
