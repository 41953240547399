import request from '@/apis/axios'
import { IResponse } from '@/apis/axios/config'

import type {
  CommonMenuInfoParam,
  CommonMenuInfoResult,
  CommonRefreshTokenParam,
  CommonRefreshTokenResult,
  PushReadInfoParam,
  PushReadInfoResult,
  PushUseInfoParam,
  PushUseInfoResult,
} from './types'
import { mock } from '~/apis/axios/service'
import { useCache } from '~/common/useCache'
import { logoutAndCleanData } from '~/common/Common'
import { jwtDecode } from 'jwt-decode'

mock.onPost('/mock/common/commonMenuInfo').reply(200, {
  code: '100',
  msg: '성공',
  data: {
    menu_cat1: [
      {
        MENU_SEQ: '2',
        MENU_NM: '\ud559\uc2b5 \uacfc\uc81c',
        MENU_PAGE_URL: '/assignments',
        MENU_SORT: '1',
        MENU_DEPTH: '1',
        MENU_CODE: '2',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navTask',
      },
      {
        MENU_SEQ: '1',
        MENU_NM: '\uc218\uc5c5 \uc77c\uc9c0',
        MENU_PAGE_URL: '/daily',
        MENU_SORT: '2',
        MENU_DEPTH: '1',
        MENU_CODE: '1',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navDaily',
      },
    ],
    menu_cat2: [
      {
        MENU_SEQ: '4',
        MENU_NM: '\ud074\ub798\uc2a4',
        MENU_PAGE_URL: '/class',
        MENU_SORT: '2',
        MENU_DEPTH: '1',
        MENU_CODE: '4',
        SITE_TYPE: 'P',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navCalss',
      },
    ],
    menu_other: [
      {
        MENU_SEQ: '5',
        MENU_NM: 'Holistic \uc628\ub77c\uc778 \ud559\uc2b5',
        MENU_PAGE_URL: 'http://3.38.149.75/other_contents/holistic',
        MENU_SORT: '1',
        MENU_DEPTH: '1',
        MENU_CODE: '5',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'Y',
        MENU_CLASS: 'navHolistic',
      },
      {
        MENU_SEQ: '6',
        MENU_NM: 'AR \ub3c5\uc11c \ud034\uc988',
        MENU_PAGE_URL: 'http://3.38.149.75/other_contents/ar',
        MENU_SORT: '2',
        MENU_DEPTH: '1',
        MENU_CODE: '6',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'Y',
        MENU_CLASS: 'navAr',
      },
      {
        MENU_SEQ: '7',
        MENU_NM: 'ONSTP \ub3d9\uc601\uc0c1 \uac15\uc758',
        MENU_PAGE_URL: 'http://3.38.149.75/other_contents/onstp',
        MENU_SORT: '3',
        MENU_DEPTH: '1',
        MENU_CODE: '7',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'Y',
        MENU_CLASS: 'navOnstp',
      },
      {
        MENU_SEQ: '8',
        MENU_NM: 'DYB Pedia \uac15\uc758',
        MENU_PAGE_URL: 'http://3.38.149.75/other_contents/pedia',
        MENU_SORT: '4',
        MENU_DEPTH: '1',
        MENU_CODE: '8',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'Y',
        MENU_CLASS: 'navPedia',
      },
      {
        MENU_SEQ: '9',
        MENU_NM: 'Highlights \ub3c4\uc11c\uad00',
        MENU_PAGE_URL: 'http://3.38.149.75/other_contents/highlights',
        MENU_SORT: '5',
        MENU_DEPTH: '1',
        MENU_CODE: '9',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'Y',
        MENU_CLASS: 'navHighlights',
      },
      {
        MENU_SEQ: '14',
        MENU_NM: "Song's \ube14\ub85c\uadf8",
        MENU_PAGE_URL:
          'https://blog.naver.com/PostList.naver?blogId=dybchoisun',
        MENU_SORT: '7',
        MENU_DEPTH: '1',
        MENU_CODE: '14',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navSongsBlog',
      },
    ],
    menu_cat3: [
      {
        MENU_SEQ: '10',
        MENU_NM: '\ub0b4 \uc815\ubcf4',
        MENU_PAGE_URL: '/myinfo',
        MENU_SORT: '1',
        MENU_DEPTH: '1',
        MENU_CODE: '10',
        SITE_TYPE: 'P',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navMy',
      },
    ],
    menu_cat4: [
      {
        MENU_SEQ: '15',
        MENU_NM: '\uc218\uac15\ub8cc \uacb0\uc81c',
        MENU_PAGE_URL: 'http://3.38.149.75/other_contents/mypage?url=pay',
        MENU_SORT: '1',
        MENU_DEPTH: '1',
        MENU_CODE: '15',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'Y',
        MENU_CLASS: 'navPay',
      },
      {
        MENU_SEQ: '16',
        MENU_NM: 'DYB \ub9c8\uc774\ud398\uc774\uc9c0',
        MENU_PAGE_URL: 'http://3.38.149.75/other_contents/mypage',
        MENU_SORT: '2',
        MENU_DEPTH: '1',
        MENU_CODE: '16',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'Y',
        MENU_CLASS: 'navDybM',
      },
    ],
    menu_bottom: [
      {
        MENU_SEQ: '11',
        MENU_NM: '\uc571 \uc124\uc815',
        MENU_PAGE_URL: null,
        MENU_SORT: '1',
        MENU_DEPTH: '1',
        MENU_CODE: '11',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navSet',
      },
      {
        MENU_SEQ: '12',
        MENU_NM: '\ub85c\uadf8\uc544\uc6c3',
        MENU_PAGE_URL: null,
        MENU_SORT: '2',
        MENU_DEPTH: '1',
        MENU_CODE: '12',
        SITE_TYPE: 'T',
        TOKEN_TYPE: 'N',
        MENU_CLASS: 'navlogout',
      },
    ],
    SHUTTLE_TYPE: 'N',
  },
})

/**
 * 0.4. 메뉴 조회
 * 로그인 - api/common/commonMenuInfo
 */
export const commonMenuInfo = (
  params: CommonMenuInfoParam
): Promise<IResponse<CommonMenuInfoResult>> => {
  //params의 UM_IDX가 0이거나 '0' 이면 엑세스 토큰에서 UM_IDX를 가져와서 params에 추가
  if (params.UM_IDX === '' || params.UM_IDX === '0') {
    const wsCache = useCache()
    if (
      wsCache.get('userInfo') != null &&
      wsCache.get('userInfo').ACCESS_TOKEN != null
    ) {
      const accessToken = wsCache.get('userInfo').ACCESS_TOKEN
      if (accessToken) {
        //accessToken(jwt) 파싱 후 UM_IDX 추가
        const jwt = jwtDecode(accessToken)
        params.UM_IDX = accessToken.UM_IDX
      } else {
        //로그인 문제 발생 시 로그아웃 처리
        logoutAndCleanData()
        return Promise.reject('로그인 정보가 없습니다.')
      }
    }
  }
  return request.get({ url: '/api/common/commonMenuInfo', params })
}

/**
 * 0.2. Refresh Token 검증
 * commonRefreshToken
 */
export const commonRefreshToken = (
  params: CommonRefreshTokenParam
): Promise<IResponse<CommonRefreshTokenResult>> => {
  return request.get({ url: '/api/common/commonRefreshToken', params })
}

/**
 * 2.5.2 알림 읽음 여부 조회
 * commonRefreshToken
 */
export const pushReadInfo = (
  params: PushReadInfoParam
): Promise<IResponse<PushReadInfoResult>> => {
  return request.get({ url: '/api/push/pushReadInfo', params })
}

/**
 * 2.5.6. 디바이
 */
export const pushUseInfo = (
  params: PushUseInfoParam
): Promise<IResponse<PushUseInfoResult>> => {
  return request.put({ url: '/api/push/pushUseInfo', params })
}

export const commonMenuInfoMock = (): Promise<
  IResponse<CommonMenuInfoResult>
> => {
  return request.mockPost({ url: '/mock/common/commonMenuInfo' })
}
