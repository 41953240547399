import { Ref } from 'vue'

export class Loading {
  private static _instance: Loading | null = null
  private readonly _loading: Ref<boolean>
  private readonly _css: Ref<string>

  private constructor() {
    this._loading = ref<boolean>(false)
    this._css = ref<string>('')
  }

  public get loading(): Ref<boolean> {
    return this._loading
  }

  public get css(): Ref<string> {
    return this._css
  }

  public static getInstance(): Loading {
    if (this._instance === null) {
      this._instance = new Loading()
    }

    return this._instance
  }

  public toggleLoading(): void {
    this._loading.value = !this._loading.value
  }

  public show(css = ''): void {
    document.body.classList.add('loading')
    this._css.value = css
    this._loading.value = true
  }

  public close(): void {
    document.body.classList.remove('loading')
    this._loading.value = false
  }
}
