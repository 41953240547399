import type { AlertDialogProps } from '~/apis/common/types'
import { Ref } from 'vue'

export class AlertDialogController {
  private static _instance: AlertDialogController | null = null
  private readonly _visible: Ref<boolean>
  private readonly _callback: Ref<AlertDialogProps>

  private constructor() {
    this._visible = ref<boolean>(false)
    this._callback = ref<AlertDialogProps>({
      title: '',
      content: '',
      confirmText: '',
      cancelText: '',
      disabledCancel: false,
      width: '',
      confirm(): void {},

      cancel(): void {},
    })
  }

  public get visible(): Ref<boolean> {
    return this._visible
  }

  public get data(): Ref<AlertDialogProps> {
    return this._callback
  }

  public static getInstance(): AlertDialogController {
    if (this._instance === null) {
      this._instance = new AlertDialogController()
    }

    return this._instance
  }

  public show(props: AlertDialogProps): void {
    this._visible.value = true
    this._callback.value = props
  }

  public close(): void {
    this._visible.value = false
    this._callback.value = {
      title: '',
      content: '',
      confirmText: '',
      cancelText: '',
      disabledCancel: false,
      width: '',
      confirm(): void {},

      cancel(): void {},
    }
  }
}
