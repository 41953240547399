export const appHead = {"meta":[{"charset":"utf-8"},{"name":"description","content":"DYB Window"},{"name":"author","content":"DYB DX"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"},{"name":"theme-color","content":"#2297FF"},{"name":"description","content":"DYB Window"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:title","property":"og:title","content":"DYB-Window "},{"hid":"og:url","property":"og:url","content":"https://www.dybwindow.com"},{"hid":"og:image","property":"og:image","content":"https://d3iltva7qxdle9.cloudfront.net/dev/unnamed.png"},{"hid":"og:description","property":"og:description","content":"DYB-Window "},{"hid":"og:image:width","property":"og:image:width","content":"512"},{"hid":"og:image:height","property":"og:image:height","content":"512"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.svg"}],"style":[],"script":[{"src":"/liveCheck.js"}],"noscript":[],"charset":"utf-8","title":"DYB Window","htmlAttrs":{"lang":"ko-KR"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'