<script lang="ts" setup>
import { useAppStore } from '~/stores/app'

const { gtag } = useGtag()
const app = useAppStore()

const reload = () => {
  location.reload(true)
}
// eslint-disable-next-line no-undef
onMounted(() => {
  gtag('event', 'open_error_page', {
    um_idx: app.userInfo?.UM_IDX,
    date: new Date().toISOString(),
  })
  throw new Error('error')
})
</script>
<template>
  <div class="main">
    <img
      alt="Nuxt Logo"
      class="img"
      src="~/assets/images/common/img_error.svg"
    />
    <div>
      <h1>화면을 불러오지 못했어요</h1>
    </div>
    <div class="desc">
      화면을 다시 불러오기 위해<br />
      아래의 방법을 시도해 주세요
    </div>
    <div class="solve">
      <p class="title">해결 방법 1</p>
      <p>아래 [화면 다시 불러오기] 버튼을 선택해 주세요.</p>
    </div>
    <div class="solve">
      <p class="title">해결 방법 2</p>
      <p>
        그래도 되지 않는다면,<br />
        아래의 방법으로 캐시를 지워보세요.
      </p>
      <p></p>
      <p>
        안드로이드: 설정 → DYB Window 검색 → 저장공간 → 캐시 삭제 선택<br />
        아이폰: 설정 → 일반 → iPhone 저장 공간 → DYB Window 검색 → 앱 정리하기
        선택
      </p>
    </div>
    <div class="solve">
      <p class="title">해결 방법 3</p>
      <p>
        위의 방법이 모두 어려우시면<br />
        앱을 삭제 후 재설치해 주세요
      </p>
    </div>
    <div class="info">
      이용에 불편을 드려 죄송합니다.<br />
      IT문의: 02-556-1604 (DYB IT팀)
    </div>
    <button class="button" @click="reload">화면 다시 불러오기</button>
  </div>
</template>
<style lang="css" scoped>
div {
  --error-width: 491px;
}

@media (max-width: 760px) {
  div {
    --error-width: 491px;
  }
}

@media (max-width: 550px) {
  div {
    --error-width: 390px;
  }
}

@media (max-width: 450px) {
  div {
    --error-width: 350px;
  }
}

.main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7f8f9;
  height: 100vh;
  padding: 32px;
  flex-direction: column;
  font: normal 1rem / 1.5 Pretendard, -apple-system, BlinkMacSystemFont,
    system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo',
    'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-weight: 400;
  color: var(--defaultColor);
  letter-spacing: -0.24px;
  gap: 16px;
}

.main img {
  padding-top: 10px;
  width: 105px;
  max-width: 87px;
}

.main h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
  color: #191919;
}

.main .desc {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #72787f;
  text-align: center;
}

.main .solve {
  padding: 16px;
  background: #ffffff;
  border: 1px solid #ffedee;
  border-radius: 8px;
  width: var(--error-width);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.main .solve .title {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #454c53;
  background: #ebebed;
  border: 1px solid #ebebed;
  border-radius: 8px;
  width: fit-content;
  padding: 2px 6px;
}

.main .solve p {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  color: #e9333e;
  text-align: center;
}

.main .info {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #72787f;
  text-align: center;
}

.main .button {
  font-size: 18px;
  font-weight: 500;
  background: #2297ff;
  border: 1px solid #2297ff;
  border-radius: 8px;
  padding: 12px 10px;
  width: var(--error-width);
  color: #ffffff;
}
</style>
