import { Menu } from '~/apis/common/types'
import { APP_TYPE, DEVICE_TYPE, SITE_TYPE } from '~/enum/enum'
import { marked } from 'marked'
import { Editor } from '@toast-ui/editor'
import { useCache, useSessionCache } from '~/common/useCache'
import {
  logout,
  openWeb,
  reqPermission,
  reqPermissionState,
  saveAutoLogin,
} from '~/plugins/message'
import { useAppStore } from '~/stores/app'
import { storeToRefs } from 'pinia'
import { AlertDialogController } from '~/common/AlertDialogController'

export const menuDevicefillter = (item: Menu) => {
  //T : 전체, P : PC전용, M : 모바일 전용
  {
    if (useDevice().isDesktopOrTablet) {
      // pc or tablet
      if (item.SITE_TYPE === SITE_TYPE.ALL || item.SITE_TYPE === SITE_TYPE.PC) {
        return item
      }
    } else {
      if (
        item.SITE_TYPE === SITE_TYPE.ALL ||
        item.SITE_TYPE === SITE_TYPE.MOBILE
      ) {
        return item
      }
    }
  }
}

/**
 * Returns the user device type based on the provided app type.
 * The app type can be one of the following values:
 *   - 'app' for mobile or desktop app
 *   - 'web' for mobile or desktop web
 *
 * The user device type can be one of the following values:
 *   - 'ANDROID_M_APP' for Android mobile app
 *   - 'IOS_M_APP' for iOS mobile app
 *   - 'ANDROID_T_APP' for Android desktop or tablet app
 *   - 'IOS_T_APP' for iOS desktop or tablet app
 *   - 'M_WEB' for mobile web
 *   - 'T_WEB' for desktop or tablet web
 *
 * @param {string} appType - The type of the app ('app' or 'web')
 * @returns {string|undefined} The user device type or undefined if the app type is invalid
 */
export const getUserDevice = (appType: string) => {
  /*
   * 1 android M app
   * 2 ios M app
   * 3 android T app
   * 4 ios T app
   * 5 M web
   * 6 T web
   * */
  if (appType === APP_TYPE.ANDROID_M_APP) {
    console.log('getUserDevice', 'DEVICE_TYPE.ANDROID_M_APP')
    return DEVICE_TYPE.ANDROID_M_APP
  } else if (appType === APP_TYPE.IOS_M_APP) {
    console.log('getUserDevice', 'DEVICE_TYPE.IOS_M_APP')
    return DEVICE_TYPE.IOS_M_APP
  } else if (appType === APP_TYPE.ANDROID_T_APP) {
    console.log('getUserDevice', 'DEVICE_TYPE.ANDROID_T_APP')
    return DEVICE_TYPE.ANDROID_T_APP
  } else if (appType === APP_TYPE.IOS_T_APP) {
    console.log('getUserDevice', 'DEVICE_TYPE.IOS_T_APP')
    return DEVICE_TYPE.IOS_T_APP
  } else if (useDevice().isMobile && appType === APP_TYPE.WEB) {
    console.log('getUserDevice', 'DEVICE_TYPE.M_WEB')
    return DEVICE_TYPE.M_WEB
  } else if (useDevice().isDesktopOrTablet && appType === APP_TYPE.WEB) {
    console.log('getUserDevice', 'DEVICE_TYPE.T_WEB')
    return DEVICE_TYPE.T_WEB
  }
}

export const app_version = import.meta.env.VITE_APP_VERSION
export const app_env = import.meta.env.VITE_ENV

export const convertURLtoFile = async (url: string) => {
  const response = await fetch(url)
  const data = await response.blob()
  // data.type은  image/jpeg형식 확장자를 추출
  let ext = data.type.split('/').pop()
  if (ext === 'jpeg') {
    ext = 'jpg'
  } else if (ext === 'mpeg') {
    ext = 'mp3'
  } else if (ext === 'webm') {
    ext = 'webm'
    // } else if (ext === 'mp4') {
    //   ext = 'mp3'
  } else if (ext === 'x-m4a') {
    ext = 'm4a'
  }

  const filename = url.split('/').pop() + '.' + ext // url 구조에 맞게 수정할 것
  const metadata = { type: data.type }
  return new File([data], filename!, metadata)
}
export const convertURLtoFilenName = async (url: string, fileNm: string) => {
  const response = await fetch(url)
  const data = await response.blob()
  // data.type은  image/jpeg형식 확장자를 추출
  let ext = data.type.split('/').pop()
  if (ext === 'jpeg') {
    ext = 'jpg'
  } else if (ext === 'mpeg') {
    ext = 'mp3'
  } else if (ext === 'webm') {
    ext = 'webm'
    // } else if (ext === 'mp4') {
    //   ext = 'mp3'
  } else if (ext === 'x-m4a') {
    ext = 'm4a'
  }

  const filename = fileNm.split('.')[0] + '.' + ext // url 구조에 맞게 수정할 것
  const metadata = { type: data.type }
  return new File([data], filename!, metadata)
}
/**
 * `marked` 라이브러리를 사용하여 주어진 마크다운 문자열을 HTML로 변환합니다.
 *
 * @param {string} markDown - 변환할 마크다운 문자열입니다
 * @returns {Promise<string>} -변환된 HTML 문자열로 해석되는 Promise입니다.
 */
export const changeMarkdown = async (markDown: string) => {
  marked.setOptions({
    renderer: new marked.Renderer(),
    gfm: true,
    breaks: true,
    pedantic: false,
  })
  return marked(markDown)
}
export const initToastView = (refs: [], texts: [], key: string) => {
  for (let i = 0; i < refs.length; i++) {
    initText({ target: refs[i] }, texts[i][key])
  }
}

export const initText = (e: any, test: string) => {
  //console.log(e)
  if (!e.target) {
    return
  }
  const editor = Editor.factory({
    el: e.target,
    initialValue: '',
    initialEditType: 'wysiwyg',
    hideModeSwitch: false,
    height: 'auto',
    toolbarItems: [],
    useCommandShortcut: false,
    usageStatistics: false,
    viewer: true,
    linkAttributes: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  })
  editor.setMarkdown(test)
}

export const logoutAndCleanData = () => {
  // 로그아웃시 캐시 데이터 삭제
  const wsCache = useCache()
  wsCache.clear()
  const wsCacheSession = useSessionCache()
  wsCacheSession.clear()
  // 로그아웃시 쿠키 삭제
  const cookie = useCookie('userInfo')
  cookie.value = undefined

  document.cookie = 'userInfo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  //쿠키 삭제
  //로그아웃시 스토어 데이터 삭제
  const app = useAppStore()
  app.init()
  sessionStorage.clear()
  //앱이라면 앱 쿠키 삭제
  logout()
  saveAutoLogin(false)
  const { push } = useRouter()
  push('/login')

  //200 지연후 이동
  setTimeout(() => {
    location.reload()
  }, 200)
}

export const parseHash = (url: string) => {
  // https://dybwindow.com/#/main
  const hash = url

  // const hash = window.location.hash //현재 URL의 해시 가져오기
  const startIdx = hash.indexOf('#/') // '#/'의 위치 찾기
  if (startIdx !== -1) {
    return hash.slice(startIdx + 1) // '/' 이후의 문자열 모두 반환
  }
  return ''
}

/**
 * Checks the device type and displays the app permission dialog if the user has not granted the necessary permissions.
 *
 * @function openAppPermissionDialog
 */
export const openAppPermissionDialog = () => {
  const app = useAppStore()
  const wsCache = useCache()
  const { appPermissionDialog } = storeToRefs(app)
  // 앱 체크 후 미동의  퍼미션이 있다면 출력
  const device = getUserDevice(app.appType)
  if (
    device === DEVICE_TYPE.ANDROID_T_APP ||
    device === DEVICE_TYPE.ANDROID_M_APP
  ) {
    const permission = reqPermissionState()
    const permissionPopup = wsCache.get('permission_popup')
    if (!permission && !permissionPopup) {
      wsCache.set('permission_popup', true)
      appPermissionDialog.value = true
    }
  } else if (
    device === DEVICE_TYPE.IOS_T_APP ||
    device === DEVICE_TYPE.IOS_M_APP
  ) {
    reqPermissionState()
    setTimeout(() => {
      const permission = wsCache.get('permission')
      const permissionPopup = wsCache.get('permission_popup')
      if (!permission && !permissionPopup) {
        wsCache.set('permission_popup', true)
        appPermissionDialog.value = true
      }
    }, 1000)
  }
}
export const openConfirmPermissionDialog = () => {
  const app = useAppStore()

  AlertDialogController.getInstance().show({
    title: '알림',
    content: '권한 설정을 위해 설정창으로 이동 합니다.',
    confirmText: '확인',
    cancelText: '취소',
    disabledCancel: false,
    width: '',
    cancel(): void {},
    confirm: () => {
      const device = getUserDevice(app.appType)
      if (
        device === DEVICE_TYPE.ANDROID_M_APP ||
        device === DEVICE_TYPE.ANDROID_T_APP
      ) {
        reqPermission()
      } else if (
        device === DEVICE_TYPE.IOS_M_APP ||
        device === DEVICE_TYPE.IOS_T_APP
      ) {
        reqPermission()
      }
    },
  })
}

export const setScrollPostionToCache = () => {
  const { x, y } = useWindowScroll()
  const router = useRouter()
  const route = router.currentRoute.value

  const wsCacheSession = useSessionCache()
  wsCacheSession.set(route.fullPath, { scrollPosition: y.value })
}

export const moveTo = (path: string) => {
  navigateTo(path)
}

export const base64toFile = (
  base64Data: string,
  filename: string,
  contentType = 'image/jpeg'
): File => {
  const sliceSize = 1024
  const byteCharacters = atob(base64Data)
  const bytesLength = byteCharacters.length
  const slicesCount = Math.ceil(bytesLength / sliceSize)
  const byteArrays = new Array<Array<Uint8Array>>(slicesCount)

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize
    const end = Math.min(begin + sliceSize, bytesLength)

    const bytes = new Array<number>(end - begin)
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0)
    }
    // @ts-ignore
    byteArrays[sliceIndex] = new Uint8Array(bytes)
  }
  // @ts-ignore
  const blob = new Blob(byteArrays, { type: contentType })
  const file = new File([blob], filename, { type: contentType })

  return file
}

// HTML element의 모든 하위 노드를 탐색하여 http:// 또는 https:// 가 있으면 <a>로 치환
export const changeLink = (element: HTMLElement) => {
  // element의 모든 하위 노드를 탐색
  const childNodes = element.childNodes
  for (let i = 0; i < childNodes.length; i++) {
    const node = childNodes[i]
    // 기존 <a> 태그인 경우
    if (node instanceof HTMLAnchorElement || node instanceof HTMLImageElement) {
      continue
    }

    // node가 HTMLElement인 경우
    if (node instanceof HTMLElement || node instanceof Text) {
      // node의 하위 노드를 탐색
      changeLink(node)
    }
  }
  // element의 innerHTML에 http:// 또는 https://를 포함하는 경우 <a>로 치환
  //console.log(element.textContent)
  //console.log(element.nodeName)
  if (
    element.nodeName === '#text' &&
    element.textContent?.match(/http(s)?:\/\//)
  ) {
    // <a>태그 생성 후 치환
    const a = document.createElement('a')
    const text = element.textContent
    const regex = /(https?:\/\/[^\s]+)/g
    const links = text.match(regex)
    //a.href = links

    a.onclick = () => {
      //console.log('click')
      openWeb(links[0])
    }
    //새창으로 열기
    //a.target = '_blank'
    a.textContent = element.textContent
    element.replaceWith(a)
  }
}
